<template>
  <div class="container">
    <TopHeader title="修改预约"></TopHeader>
    <div class="main-container">
      <div class="main-content p10">
        <div class="main-items">
          <div class="items-title-content">
            <div class="title-left">
              <div class="title-left-remark">来</div>
              <div class="title-left-name">来访信息</div>
            </div>
            <div class="title-right" v-if="data.status === 6 || data.status === 7 || data.status === 8">
              <img class="title-right-img" src="../../assets/images/inviteFailure.png" alt="">
            </div>
            <div class="title-right" v-if="data.status === 4 || data.status === 5">
              <img class="title-right-img" src="../../assets/images/inviteNotPass.png" alt="">
            </div>
          </div>
          <CommonBorder></CommonBorder>
          <div class="items-content">
            <div class="items-content-title">被访人姓名</div>
            <div class="items-content-info">{{ data.callName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">被访人部门</div>
            <div class="items-content-info">{{ data.departmentName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访时间</div>
            <div class="items-content-info">
              <van-field
                v-model="start"
                right-icon="arrow-down"
                readonly
                input-align="right"
                placeholder="请选择来访时间"
                @click="isStart = true"
              />
              <van-popup v-model="isStart" position="bottom">
                <van-datetime-picker
                  v-model="data.start"
                  type="datetime"
                  confirm-button-text="确定"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :formatter="formatter"
                  @confirm="startConfirmFn"
                  @cancel="isStart=false"
                />
              </van-popup>
            </div>
          </div>
          <div class="items-content">
            <div class="items-content-title">离开时间</div>
            <div class="items-content-info">
              <van-field
                v-model="end"
                right-icon="arrow-down"
                input-align="right"
                readonly
                placeholder="请选择离开时间"
                @click="isEnd = true"
              />
              <van-popup v-model="isEnd" position="bottom">
                <van-datetime-picker
                  v-model="data.end"
                  type="datetime"
                  confirm-button-text="确定"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :formatter="formatter"
                  @confirm="endConfirmFn"
                  @cancel="isEnd=false"
                />
              </van-popup>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content p5">
        <div class="main-items">
          <div class="items-title-content">
            <div class="title-left">
              <div class="title-left-remark">访</div>
              <div class="title-left-name">访客信息</div>
            </div>
            <div class="title-right" v-if="data.status === 6 || data.status === 7 || data.status === 8">
              <img class="title-right-img" src="../../assets/images/inviteFailure.png" alt="">
            </div>
            <div class="title-right" v-if="data.status === 4 || data.status === 5">
              <img class="title-right-img" src="../../assets/images/inviteNotPass.png" alt="">
            </div>
          </div>
          <CommonBorder></CommonBorder>
          <div class="items-content">
            <div class="items-content-title">访客姓名</div>
            <div class="items-content-info">{{ data.visitName }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访单位</div>
            <div class="items-content-info">{{ data.unit }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">来访事由</div>
            <div class="items-content-info">{{ reasonMap[data.reason] }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">手机号码</div>
            <div class="items-content-info">{{ data.phone }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">身份证号码</div>
            <div class="items-content-info">{{ data.card }}</div>
          </div>
          <div class="items-content">
            <div class="items-content-title">随行人数</div>
            <div class="items-content-info">
              <van-field
                v-model="numText"
                right-icon="arrow-down"
                readonly
                input-align="right"
                placeholder="请选择随行人数"
                @click="isNumList=true"
              />
              <van-popup v-model="isNumList" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="numList"
                  confirm-button-text="确定"
                  value-key="lable"
                  @confirm="numConfirm"
                  @cancel="isNumList=false"
                />
              </van-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="save-content">
      <van-button class="save-btn" @click="updateVisitFn">保存</van-button>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import TopHeader from '@/components/topHeader/index.vue'
import CommonBorder from '@/components/commonBorder/index.vue'
import { updateVisitAPI } from "@/api/invite";
import { Toast } from "vant";
export default {
  name: "InviteEdit",
  components: {
    TopHeader,
    CommonBorder
  },
  data() {
    return {
      cancel,
      data: {},
      isNumList: false,
      isStart: false,
      isEnd: false,
      minDate: new Date(),
      maxDate: new Date(),
      numList: [
        {
          lable: "1至5人",
          value: 0,
        },
        {
          lable: "大于5人",
          value: 1,
        },
      ],
      start: "",
      end: "",
      numText: "",
      visit: {
        start: "",
        end: "",
        num: "",
      },
      reasonMap: {
        0: "开会",
        1: "面试",
        2: "拜访",
        3: "合作",
        4: "洽谈",
        5: "访友",
        6: "推销",
        7: "其他",
      }
    };
  },
  created() {
    var date = new Date();
    this.maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate() + 7
    );
    this.data = JSON.parse(this.$route.query.item);
    this.visit = {
      start: this.data.start,
      end: this.data.end,
      num: this.data.num,
    };
    this.numText = this.data.num === 0 ? "1至5人" : "大于5人";
    this.start = this.data.start;
    this.end = this.data.end;
  },
  methods: {
    toBack() {
      this.$router.back();
    },
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "year") {
        return `${val}年`;
      } else if (type === "hour") {
        return `${val}时`;
      } else if (type === "minute") {
        return `${val}分`;
      }
      return val;
    },
    startConfirmFn(val) {
      if (val) {
        this.start = this.timestampToTime(val);
        this.visit.start = this.timestampToTime(val);
        this.isStart = false;
      } else {
        this.isStart = false;
      }
    },
    endConfirmFn(val) {
      if (val) {
        this.end = this.timestampToTime(val);
        this.visit.end = this.timestampToTime(val);
        this.isEnd = false;
      } else {
        this.isEnd = false;
      }
    },
    timestampToTime(time) {
      var date = time;
      var y = date.getFullYear();
      var M = date.getMonth() + 1;
      if (M < 10) {
        M = "0" + M;
      }
      var d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      var h = date.getHours();
      if (h < 10) {
        h = "0" + h;
      }
      var m = date.getMinutes();
      if (m < 10) {
        m = "0" + m;
      }
      return `${y}-${M}-${d} ${h}:${m}:00`;
    },
    numConfirm(val) {
      if (val) {
        this.numText = val.lable;
        this.visit.num = val.value;
        this.isNumList = false;
      } else {
        this.isNumList = false;
      }
    },
    async updateVisitFn() {
      if (this.visit.start === "" || this.visit.start > this.Hebdomad()) {
        Toast.fail({
          message: "来访时间未选择或选择时间大于7天!",
        });
        return;
      }
      if (this.visit.end !== "" && this.visit.end > this.Hebdomad()) {
        Toast.fail({
          message: "离开时间选择范围不能大于7天!",
        });
        return;
      }
      if (this.visit.end < this.visit.start) {
        Toast.fail({
          message: '来访时间不能小于离开时间!'
        })
        return;
      }

      const res = await updateVisitAPI({ visit: this.visit }, this.data.id);
      if (res.code === 0) {
        Toast.success({
          message: "修改成功!",
        });
        this.$router.push({ name: "InviteList" });
      }
    },
    Hebdomad() {
      var date = new Date();
      var oneweekdate = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
      var y = oneweekdate.getFullYear() + "-";
      var m =
        (oneweekdate.getMonth() + 1 < 10
          ? "0" + (oneweekdate.getMonth() + 1)
          : oneweekdate.getMonth() + 1) + "-";
      var d =
        oneweekdate.getDate() < 10
          ? "0" + oneweekdate.getDate()
          : oneweekdate.getDate();
      var s =
        (oneweekdate.getHours() < 10
          ? "0" + oneweekdate.getHours()
          : oneweekdate.getHours()) + ":"; //获得小时
      var f =
        (oneweekdate.getMinutes() < 10
          ? "0" + oneweekdate.getMinutes()
          : oneweekdate.getMinutes()) + ":"; //获得分钟
      var miao = oneweekdate.getSeconds(); //获得秒数
      return y + m + d + " " + s + f + miao;
    },
  },
};
</script>

<style lang='scss' scoped>
.container{
  background: #f7f7f7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .main-container{
    flex: 1;
    overflow: auto;
    .main-content{
      box-sizing: border-box;
      .main-items{
        background: #fff;
        border-radius: 10px;
        .items-title-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px;
          box-sizing: border-box;
          padding: 0px 16px;
          justify-content: space-between;
          .title-left{
            display: flex;
            flex-direction: row;
            .title-left-remark{
              background: #C7000B;
              color: #fff;
              width: 21px;
              height: 21px;
              font-size: 12px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              margin-right: 4px;
            }
            .title-left-name{
              color: #333;
              font-weight: 700;
            }
          }
          .title-right{
            margin-right: -16px;
            .title-right-img{
              height: 49px;
              width:65px;
              vertical-align: bottom;
            }
          }
        }
        .items-content{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          height: 44px;
          box-sizing: border-box;
          padding: 0px 16px;
          .items-content-title{
            color: #333;
          }
          .items-content-info{
            color: #666;
          }
        }
      }
    }
    .p10{
      padding: 10px 16px;
    }
    .p5{
      padding: 5px 16px;
    }
  }
  .save-content{
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    .save-btn{
      width: 100%;
      background: #FA5050;
      color: #fff;
      border-color: #FA5050;
      font-size: 18px;
    }
  }
}

::v-deep .van-cell{
  padding: 0;
}
::v-deep .van-field__control{
  color: #666;
  font-size: 16px;
}
::v-deep .van-picker__toolbar{
  background: #f1f1f1;
  height: 50px;
}
::v-deep .van-picker__cancel{
  font-size: 16px;
  color: #666;
}
::v-deep .van-picker__confirm{
  font-size: 16px;
  color: #FA5050;
}
::v-deep .van-ellipsis{
  color: #333;
}
</style>
