// /api/icbc/visit
import request from '@/utils/request'

export function visitAddAPI(data) {
    return request({
        url: '/abc/visit',
        method: 'post',
        data
    })
}

// 获取部门   
export function getDepartmentListAPI(url, id) {
    return request({
        url: `/visit/department?url=${url}&organizationId=${id}`,
        method: 'get',
    })
}


// 访客提交填写表格  /visit/1
export function submitVisitAPI(data, id) {
    return request({
        url: `/visit/${id}`,
        method: 'put',
        data
    })
}

// 访客查看邀请    /visit/url
export function queryVisitAPI(url) {
    return request({
        url: `/visit/url?url=${url}`,
        method: 'get',
    })
}


// 获取审核列表    /api/icbc/visit/paginationVerify
export function getVisitListAPI(pages) {
    return request({
        url: `/icbc/visit/paginationVerify?current=${pages.current}&size=${pages.size}&status=${pages.status}`,
        method: 'get',
    })
}


// 审核   
export function auditVisitAPI(data, id) {
    return request({
        url: `/abc/visit/status/${id}?status=${data}`,
        method: 'patch',

    })
}


// /icbc/visitUpdate/1  更新审核  
export function updateVisitAPI(data, id) {
    return request({
        url: `/abc/visitUpdate/${id}`,
        method: 'put',
        data
    })
}

// 获取商户系统设置接口
export function getUserSystemSetupAPI(id) {
    return request({
        url: `/organization/setting/${id}`,
        method: 'get',
    })
}


// 获取生成二维码需要的信息  @GetMapping("/visit/passCode/")
export function getUserInfoAPI(data) {
    return request({
        url: `/visit/passCode`,
        method: 'post',
        data
    })
}